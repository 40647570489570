<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <simple-list
      :title="'Publicidad'"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :hideFooter="true"
      :multiSort="false"
      :loading="loading"
      :class="'card mb-4'"
      :sortable="true"
      :page="page"
      :pages="pages"
      :extraButtoms="false"
      :showCreate="false"
      :showDelete="false"
      :buttonLink="'empresas/ficha'"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
    />
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">Crear Publicidad</h3>
      <h3 class="text-center" v-if="flow == 'edit'">Detalle de la Publicidad</h3>
      <div class="row" v-if="flow == 'edit'" style="width: 80%; margin: 0px auto">
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h2 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_print_count }}
              </h2>
              <p class="text-muted mt-2 mb-0">Visualizaciones</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h2 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_click_count }}
              </h2>
              <p class="text-muted mt-2 mb-0">Clicks</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h2 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_print_time }}
              </h2>
              <p class="text-muted mt-2 mb-0">Tiempo de exposición</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="padding: 2rem; margin: 20px">
        <h5 style="margin-bottom: 20px">Datos generales</h5>

        <div class="row">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                readonly
                :rules="[(v) => !!v || 'El cliente es requerido']"
                :items="users"
                :item-text="'fullName'"
                @change="getCompanies(userId)"
                :item-value="'id'"
                v-model="userId"
                label="Cliente"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                readonly
                :rules="[(v) => !!v || 'La empresa es requerida']"
                :items="companies"
                :item-text="'name'"
                :item-value="'id'"
                v-model="companyId"
                label="Empresa"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                :rules="descriptionRules"
                label="Nombre"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                :rules="descriptionRules"
                label="Redirección"
                v-model="redirection"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                label="Orden"
                v-model="order"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                readonly
                :rules="[(v) => !!v || 'El tipo de publicidad es requerido']"
                :items="types"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                label="Tipo"
                type="text"
                :hint="typeMessage"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                :rules="descriptionRules"
                label="Fecha de incio de destacado"
                v-model="outstandingStart"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                :rules="descriptionRules"
                label="Fecha final de destacado"
                v-model="outstandingEnd"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                readonly
                required
                :rules="descriptionRules"
                label="Fecha de vencimiento"
                v-model="expiratedAt"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <multiselect
                disabled
                track-by="id"
                label="name"
                placeholder="Seleccionar países"
                v-model="selectedCountries"
                :custom-label="nameWithIso2"
                :options="filteredCountries"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox disabled label=" Activa" v-model="active"></v-checkbox>
            </v-col>
          </v-form>
        </div>
      </div>

      <div
        v-if="flow == 'edit'"
        class="card"
        style="
          padding: 1rem;
          margin: 0px 20px 20px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <h5>Vista previa</h5>
        <div
          class="container"
          v-if="item.type == 'horizontal1' || item.type == 'horizontal2'"
        >
          <span style="font-size: 11px">Publicidad</span>
          <a :href="redirection" class="btn" target="_blank" rel="noopener">
            <img :src="item.image.route" :alt="item.name" style="width: 100%"
          /></a>
        </div>
        <div
          class="container"
          v-if="item.type == 'vertical1' || item.type == 'vertical2'"
        >
          <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-12"></div>
            <div class="col-lg-3 col-md-4 col-sm-12">
              <div class="row">
                <span class="text-center" style="font-size: 11px; margin: auto"
                  >Publicidad</span
                >
              </div>

              <div class="row">
                <a :href="redirection" class="btn" target="_blank" rel="noopener">
                  <img :src="item.image.route" :alt="item.name" style="width: 100%"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="$modal.hide('crudModal')"
          >
            Cerrar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  const date = new Date()
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'UserAdvertisements',
    components: {
      SimpleList,
      Multiselect,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        companies: [],
        loading: false,
        headers: [
          { text: 'Cliente', value: 'userFullName' },
          { text: 'Empresa', value: 'company.name' },
          { text: 'Nombre', value: 'name' },
          { text: 'Activo', value: 'activeText' },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Expiración', value: 'expirated_at' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '110px' },
        ],
        types: [
          { code: 'horizontal1', name: 'Banner horizontal principal' },
          { code: 'horizontal2', name: 'Banner horizontal secundario' },
          { code: 'vertical1', name: 'Banner vertical lateral' },
          { code: 'vertical2', name: 'Banner vertical cuadrado' },
        ],
        items: [],
        users: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        active: false,
        name: '',
        redirection: '',
        userId: '',
        countryId: '',
        companyId: '',
        order: 1,
        type: 'vertical',
        image: null,
        outstandingStart: new Date().toISOString().split('T')[0],
        outstandingEnd: new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0],
        expiratedAt: new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0],
        countries: [],
        selectedCountries: [],
        countryText: '',
        loadingText: 'Cargando registros',
        valid: true,
        descriptionRules: [
          (v) => !!v || 'La descripción es requerida',
          (v) => v.length <= 100 || 'La descripción debe poser menos de 100 caracteres',
        ],
      }
    },
    created() {
      this.getAdvertisements()
      this.getCountries()
      this.getUsers()
    },
    computed: {
      likesAllCountry() {
        return this.selectedCountries.length === this.filteredCountries.length
      },
      likeSomeCountry() {
        return this.selectedCountries.length > 0 && !this.likesAllCountry
      },
      icon() {
        if (this.likesAllCountry) return 'mdi-close-box'
        if (this.likeSomeCountry) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      filteredCountries() {
        if (this.countryText != '') {
          return this.countries.filter((country) =>
            country.name.toLowerCase().includes(this.countryText)
          )
        } else {
          return this.countries
        }
      },
      countryCode() {
        if (localStorage.getItem('selectedCountryCode')) {
          return localStorage.getItem('selectedCountryCode')
        } else if (localStorage.getItem('countryCode')) {
          return localStorage.getItem('countryCode')
        } else {
          let location
          axios.get('http://ip-api.com/json').then((response) => {
            location = response.data
            localStorage.setItem('countryCode', location.countryCode)
            return location.countryCode
          })
        }
      },
      typeMessage() {
        switch (this.type) {
          case 'vertical1':
            return 'La imagen será redimensionada a 300x400px'
          case 'vertical2':
            return 'La imagen será redimensionada a 250x250px'
          case 'horizontal1':
            return 'La imagen será redimensionada a 1920x540px'
          case 'horizontal2':
            return 'La imagen será redimensionada a 1920x300px'
          default:
            return ''
        }
      },
    },
    methods: {
      nameWithIso2({ name, iso2 }) {
        return `${name} (${iso2})`
      },

      async getAdvertisements() {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisements?page=' +
              this.page +
              '&withStats=1'
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.page = response.data.from
            this.pages = response.data.last_page
            this.items.map((x) => {
              let activeText
              switch (x.active) {
                case 1:
                  activeText = 'Si'
                  break
                case 0:
                  activeText = 'No'
                  break
                default:
                  activeText = ''
                  break
              }
              x.activeText = activeText
              let typeText
              switch (x.type) {
                case 'vertical1':
                  typeText = 'Banner vertical lateral'
                  break
                case 'vertical2':
                  typeText = 'Banner vertical cuadrado'
                  break
                case 'horizontal1':
                  typeText = 'Banner horizontal principal'
                  break
                case 'horizontal2':
                  typeText = 'Banner horizontal secundario'
                  break
                default:
                  typeText = ''
                  break
              }
              x.userFullName = x.user.name + ' ' + x.user.lastname + ' | ' + x.user.email
              x.typeText = typeText
            })
            this.loading = false
          })
      },

      async getCountries() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300' + this.countryId)
          .then((response) => {
            this.countries = response.data
            this.countries.map((country) => {
              country.code = country.id
            })
            this.selectedCountries.push(
              this.countries.filter((country) => country.iso2 == this.countryCode).shift()
            )
            this.loading = false
          })
      },

      async getCompanies(userId) {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'companies?user_id=' + userId)
          .then((response) => {
            this.companies = response.data.data
            this.loading = false
          })
      },

      async getUsers() {
        this.loading = true
        axios.get(process.env.VUE_APP_API_DIRECTORY + 'users').then((response) => {
          this.users = response.data.data
          this.users.map((x) => {
            x.fullName = x.name + ' ' + x.lastname + ' | ' + x.email
          })
          this.loading = false
        })
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.image = null
        this.name = this.item.name
        this.redirection = this.item.redirection
        this.userId = this.item.user_id
        this.companyId = this.item.company_id
        this.type = this.item.type
        this.outstandingStart = new Date(this.item.outstanding_start)
          .toISOString()
          .split('T')[0]
        this.outstandingEnd = new Date(this.item.outstanding_end)
          .toISOString()
          .split('T')[0]
        this.expiratedAt = new Date(this.item.expirated_at).toISOString().split('T')[0]
        this.order = this.item.order
        this.active = this.item.active == 0 ? false : true
        this.selectedCountries = this.item.countries
        this.$modal.show('crudModal')
      },

      async saveItem() {
        let formData = new FormData()
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        switch (this.type) {
          case 'vertical1':
            formData.append('fileResize[width]', 300)
            formData.append('fileResize[height]', 400)
            break
          case 'vertical2':
            formData.append('fileResize[width]', 250)
            formData.append('fileResize[height]', 250)
            break
          case 'horizontal1':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 540)
            break
          case 'horizontal2':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 300)
            break
          default:
            break
        }
        formData.append('file', this.image)
        if (this.item.image) {
          formData.append('fileId', this.item.image.id)
        }
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
        formData.append('user_id', this.userId)
        formData.append('name', this.name)
        formData.append('company_id', this.companyId)
        formData.append('redirection', this.redirection)
        formData.append('order', this.order)
        formData.append('type', this.type)
        formData.append(
          'outstanding_start',
          new Date(this.outstandingStart).toISOString()
        )
        formData.append('outstanding_end', new Date(this.outstandingEnd).toISOString())
        formData.append('expirated_at', new Date(this.expiratedAt).toISOString())
        formData.append('active', this.active)
        this.selectedCountries.forEach((country) => {
          formData.append('countries[]', country.id)
        })
        formData.append('print_count', 0)
        formData.append('print_time', 0)
        formData.append('click_count', 0)

        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'advertisements/' + this.item.id,
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getAdvertisements()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getAdvertisements()
      },
    },
  }
</script>
<style lang="scss">
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  .v-text-field input {
    line-height: 15px;
    padding: 5px 0 3px;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 0px;
  }
  .vm--modal {
    height: auto !important;
    max-height: 80% !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
